import { useEffect } from 'react';

import { useRouter } from 'next/router';
import { NextPage } from 'next';

import { routes } from '~/constants/routes';

const InitialPage: NextPage = () => {
  const { push } = useRouter();

  useEffect(() => {
    push(routes.notFound);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default InitialPage;
